import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";

import PageContainer from "../components/layout/page-container";
import PageSection from "../components/layout/page-section";

import DetailedList from "../components/browser/detailed-list";
import VenuesFilter from "../components/browser/venues-filter";

class VenuesDirectoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.data = props.data;
    this.pageContext = props.pageContext;

    this.groupVenuesAlphabetically = this.groupVenuesAlphabetically.bind(this);
  }

  groupVenuesAlphabetically(venues) {
    const letters = venues.map(({ node }) => {
      return node.canonical_name.substring(0, 1);
    }).filter((letter, idx, self) => self.indexOf(letter) === idx);

    return letters.map((letter) => {
      return venues.filter(({ node }) => {
        return node.canonical_name.substring(0, 1) === letter;
      });
    });
  }

  render() {
    const venuesWithPerformances = this.data.allVenuesJson.edges.filter(({ node }) => {
      const venueReference = node.reference;

      let hasPerformance = false;

      this.data.allPerformancesJson.edges.forEach(({ node }) => {
        if (node.venue === venueReference) {
          hasPerformance = true;
        }
      });

      return hasPerformance;
    });

    const groupedVenues = this.groupVenuesAlphabetically(venuesWithPerformances);

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Venues</h1>
          </PageSection>
          <PageSection topPadding="sm">
            <VenuesFilter filtered={this.pageContext.slug} />
          </PageSection>
          {groupedVenues.map((venues) => {
            return (
              <PageSection topPadding="md">
                <h3>{venues[0].node.canonical_name.substring(0, 1)}</h3>
                <DetailedList elements={venues}
                              categoryUnit='venue'
                              category='venues'
                              images={this.data.allImagesJson}
                              performances={this.data.allPerformancesJson}
                              narratives={this.data.allNarrativesJson}
                />
              </PageSection>
            );
          })}
          <PageSection topPadding="md">
            <VenuesFilter filtered={this.pageContext.slug} />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default VenuesDirectoryPage;

export const query = graphql`
  query VenuesDirectoryQuery($filterRegex: String!) {
    allVenuesJson(
      filter: {reviewed: {eq: true}, canonical_name: {regex: $filterRegex}},
      sort: {fields: [canonical_name], order: ASC}
    ) {
      totalCount
      edges {
        node {
          reference
          canonical_name
        }
      }
    }
    allPerformancesJson(filter: {reviewed: {eq: true}}) {
      edges {
        node {
          venue
        }
      }
    }
    allNarrativesJson {
      edges {
        node {
          venues
        }
      }
    }
    allImagesJson {
      edges {
        node {
          venues
        }
      }
    }
  }
`;